import React, {Fragment, useState} from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from "axios";
import {toast} from "react-toastify";
import base_url from "../appRoute";


function RegistrationForm() {
    const { register, handleSubmit, formState: { errors },reset } = useForm();
    const [loading,setLoading] = useState(false);
    const onSubmit = (data) => {
        setLoading(true);
        axios.post(`${base_url}/api/competitor/saveCompetitor`,data,{
            headers:{
                "authorization":'jurc@123'
            }
        }).then((res)=>{
            console.log(res.data);
            reset()
            setLoading(false);
            // reset();
            toast.success("Registration successful.");
        }).catch((err)=>{
            setLoading(false);
            if(err.response.status===409){
                toast.error("This team already exist.");
            }else{
                toast.error("Try later.")
            }
        })
    };

    const registrationForm = [
        {id:0,type:"text",label:"Enter Team Name", name: "team", required: true, placeholder: ""},
        { id: 1, type: "email", label: "Enter Email", name: "email", required: true, placeholder: "" },
        { id: 2, type: "text", label: "Enter Department Name", name: "department", required: true, placeholder: "",
          
        },

        { id: 3, type: "text", label: "Enter Member Name 1", name: "name1", required: true, placeholder: "" },
        { id: 4, type: "select", label: "Select Batch", name: "batch1", required: true, placeholder: "",
            options: [52, 51, 50, 49, 48, 47, 46, 45] },

        { id: 5, type: "text", label: "Enter Member Name 2 (optional)", name: "name2", required: false, placeholder: "" },
        { id: 6, type: "select", label: "Select Batch", name: "batch2", required: false, placeholder: "",
            options: [52, 51, 50, 49, 48, 47, 46, 45] },

        { id: 7, type: "text", label: "Enter Member Name 3 (optional)", name: "name3", required: false, placeholder: "" },
        { id: 8, type: "select", label: "Select Batch", name: "batch3", required: false, placeholder: "",
            options: [52, 51, 50, 49, 48, 47, 46, 45] },
    ];

    return (<div className="formcss">
        <Container fluid="true" className="regiform">
            <h1 className="subTitle text-center text-dark">Provide your information below to participate in Web Code Craft 2024.</h1>
            <Row className="mx-3 mt-5">
                <form className="box p-3" onSubmit={handleSubmit(onSubmit)}>
                    {registrationForm.map((data, idx) => (
                        <Fragment key={data.id}>
                            {data.type === "select" ? (
                                <div className="mb-2">
                                    <label htmlFor={data.name} className="form-label mb-0 ">{data.label}</label>
                                    <select
                                        id={data.name}
                                        className="form-control blackDescription"
                                        {...register(data.name, { required: data.required })}
                                    >
                                        <option value="">Select {data.placeholder.toLowerCase()}</option>
                                        {data.options.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {errors[data.name] && <span className="text-danger">This field is required</span>}
                                </div>
                            ) : (
                                <div key={data.id} className="mb-2">
                                    <label htmlFor={data.name} className="form-label mb-0">{data.label}</label>
                                    <input
                                        type={data.type}
                                        id={data.name}
                                        className="form-control"
                                        {...register(data.name, { required: data.required })}
                                        placeholder={data.placeholder}
                                    />
                                    {errors[data.name] && <span className="text-danger">This field is required</span>}
                                </div>
                            )}
                        </Fragment>
                    ))}

                    <div>
                        {
                            loading ? (
                                <div>Loading...</div>
                            ):(
                                <Button type="submit" variant="outline-primary"> Submit </Button>
                            )
                        }
                    </div>
                </form>
            </Row>
        </Container>
        </div>
    );
}

export default RegistrationForm;
