import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import homeConfig from "../Config/HomeConfig";


function Mission(props) {
    return (
       <Container fluid="true" className="mt-3">
           <Row className="mx-0">
               <Col md={7} lg={8} sm={12}>
                   <div>
                       <h4 className="blackTitle">
                           {homeConfig.welcomeMessage.title}
                       </h4>
                       <p className="blackDescription">
                           {homeConfig.welcomeMessage.description}
                       </p>
                   </div>
                   <div>
                       <h4 className="blackTitle">  {homeConfig.ourMission.title}</h4>
                       <p className="blackDescription">
                           {homeConfig.ourMission.description}
                       </p>
                   </div>
                   <div>
                       <h4 className="blackTitle">  {homeConfig.activities.title}</h4>
                       <p className="blackDescription">
                           {homeConfig.activities.description}
                       </p>
                   </div>

               </Col>
               <Col md={5} lg={4} sm={12}>
                   <Row>
                       <Col md={5} lg={5} sm={5}>
                           <img className="eventImg"
                                src={homeConfig.image1.image}
                                alt=""/>
                       </Col>
                       <Col md={7} lg={7} sm={7}>
                           <h3 className="blackTitle">{homeConfig.image1.title}</h3>
                           <h4 className="blackDescription">{homeConfig.image1.description}</h4>
                       </Col>
                   </Row>
                   <Row className="mt-sm-2">
                       <Col md={5} lg={5} sm={5}>
                           <img className="eventImg"
                                src={homeConfig.image2.image}
                                alt=""/>
                       </Col>
                       <Col md={7} lg={7} sm={7}>
                           <h3 className="blackTitle">{homeConfig.image2.title}</h3>
                           <h4 className="blackDescription">{homeConfig.image2.description}</h4>
                       </Col>
                   </Row>
               </Col>
           </Row>
       </Container>
    );
}

export default Mission;
