import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import CountUp from 'react-countup';

function CountDown(props) {
    return (
       <Container fluid="true" className="counter mt-3 text-center">
           <Row className="mx-0 counterOverlay align-items-center" >
               <Col>
                   <CountUp start={0} end={10} delay={0}>
                       {({ countUpRef }) => (
                           <span className="title" ref={countUpRef}/>
                       )}
                   </CountUp>
                  <h4 className="description">COMPETITIONS</h4>
               </Col>
               <Col>
                   <CountUp start={0} end={6} delay={0}>
                       {({ countUpRef }) => (
                           <span className="title" ref={countUpRef}/>
                       )}
                   </CountUp>
                   <h4 className="description">SEMINARS</h4>
               </Col>
               <Col>
                   <CountUp start={0} end={15} delay={0}>
                       {({ countUpRef }) => (
                           <span className="title" ref={countUpRef}/>
                       )}
                   </CountUp>

                   <h4 className="description">WORKSHOPS</h4>
               </Col>
           </Row>
          
       </Container>
    );
}

export default CountDown;

