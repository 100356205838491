import React, {Fragment} from 'react';
import TopMenu from "../Components/TopMenu";
import Menu from "../Components/Menu";
import TopBanner from "../Components/TopBanner";
import Mission from "../Components/Mission";
import CountDown from "../Components/CountDown";
import Footer from "../Components/Footer";

function Home(props) {
    return (
        <Fragment>
           {/*   <TopMenu/>  */}
            <Menu/>
            <TopBanner/>
            <Mission/>
            <CountDown/>
            <Footer/>
        </Fragment>
    );
}

export default Home;
