import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/Assets/CSS/Menu.css"
import "../src/Assets/CSS/Responsive.css"
import "../src/Assets/CSS/Custom.css"
import Home from "./Pages/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import RegistrationPage from "./Pages/RegistrationPage";
import {Fragment} from "react";
import Toast from "./Components/Toast";

function App() {
  return (
  <Fragment>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/registration" element={<RegistrationPage/>}/>
          </Routes>
      </BrowserRouter>
      <Toast/>
  </Fragment>
  );
}

export default App;
