import React, {Fragment} from 'react';
import Menu from "../Components/Menu";
import TopMenu from "../Components/TopMenu";
import Footer from "../Components/Footer";
import Competition from "../Components/Competition";

function RegistrationPage(props) {
    return (
        <Fragment>
       {/*   <TopMenu/>  */}
            <Menu/>
            <Competition/>
            <Footer/>
        </Fragment>
    );
}

export default RegistrationPage;
