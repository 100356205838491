import React from 'react';
import { Container, Row, Carousel } from 'react-bootstrap'; // Import Carousel
import { useState, useEffect } from 'react';

import banner1 from "../Assets/Images/banner1.jpeg";
import img1 from "../Assets/Images/Banner/Workshop.jpeg"; // Import other images
import img2 from "../Assets/Images/Banner/projects.jpg";
import img3 from "../Assets/Images/Banner/IftarMahfil2024.jpg";
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';

function TopBanner(props) {

    const [gap, setGap] = useState('200px'); // Default gap
    const [gap1, setGap1] = useState('200px'); // Default gap
    // Function to update the gap based on screen width
    const updateGap = () => {
        const width = window.innerWidth;

        if (width < 650) {
            setGap('0px'); // Small gap for narrow screens
        } else if (width < 1300) {
            setGap('40px'); // Medium gap for medium screens
        } else {
            setGap('250px'); // Larger gap for wide screens
        }

        if (width < 650) {
            setGap1('0px'); // Small gap for narrow screens
        } else if (width < 1300) {
            setGap1('40px'); // Medium gap for medium screens
        } else {
            setGap1('250px'); // Larger gap for wide screens
        }

    };

    // Set up event listener on mount and clean up on unmount
    useEffect(() => {
        updateGap(); // Initial gap update
        window.addEventListener('resize', updateGap); // Update gap on resize

        return () => {
            window.removeEventListener('resize', updateGap); // Clean up on unmount
        };
    }, []); // Empty dependency array means this effect runs once on mount

    const [padding, setPadding] = useState('10px');

    // Function to calculate padding based on viewport width and height
    const calculatePadding = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        if (width > 800) {
            const newPadding = width * 0.17; // 5% of the smaller dimension
            setPadding(`${newPadding}px`);
        }
        else setPadding(`${0}px`);

    };

    useEffect(() => {
        // Initial calculation
        calculatePadding();

        // Update padding on resize
        window.addEventListener('resize', calculatePadding);
        return () => window.removeEventListener('resize', calculatePadding);
    }, []);



    return (
        <div className='banner' style={{ paddingLeft: padding, paddingRight: padding }}>
            <Container fluid={true} className="mt-0">
                <Row className="mx-0">
                    {/* <img className="banner" src={banner1} alt="banner1" /> */}
                </Row>
            </Container>

            <Carousel fade interval={3000} controls={true} indicators={true} >
                <Carousel.Item className="zoom">
                    <img
                        className="responsive-img"
                        src={img1}
                        alt="First slide"
                    />
                    {/* <Carousel.Caption className="caption-top-center">
                        <h3 className="title">First Slide</h3>
                        <p className="description">Description for the first slide.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item className="zoom">
                    <img
                        className="responsive-img"
                        src={img2}
                        alt="Second slide"
                    />
                    {/* <Carousel.Caption className="caption-top-center">
                        <h3 className="title">Second Slide</h3>
                        <p className="description">Description for the second slide.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item className="zoom">
                    <img
                        className="responsive-img"
                        src={img3}
                        alt="Third slide"
                    />
                    {/* <Carousel.Caption className="caption-top-center">
                        <h3 className="title">Third Slide</h3>
                        <p className="description">Description for the third slide.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
        </div>
    );
}
export default TopBanner;   