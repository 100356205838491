import img1 from "../Assets/Images/Banner/conference.jpg";
import img2 from "../Assets/Images/Banner/practice1.jpeg";

const homeConfig = {
    welcomeMessage:{
        title: "Welcome",
        description: "Jahangirnagar University Robotics Club is dedicated to foster a community of innovation, creativity, and collaboration. Whether you’re a beginner or an experienced enthusiast, we invite you to join us on this journey of discovery. Here, we engage in hands-on projects, workshops, and competitions that challenge our skills and ignite our passion for technology.",
    },
    ourMission: {
        title: "Our Mission",
        description:"Our mission is to inspire and empower students to explore the exciting field of robotics by engaging in projects, competitions, and outreach activities. We aim to develop practical skills and knowledge while encouraging ethical practices and responsible use of technology. We strive to cultivate a culture of curiosity and excellence, preparing our members for future challenges in the world of robotics and engineering.",
    },
    activities:{
        title: "Activities",
        description:"We offer a diverse range of activities designed to engage and inspire our members. We host hands-on workshops focusing on programming, electronics, and robotics design, often featuring guest lectures from industry experts. Members have the opportunity to participate in national and international robotics competitions, as well as intra-club contests that encourage teamwork and innovation. Project development allows members to design and build their own robots. We also promote research and innovation, encouraging members to present their findings at conferences. Additionally, we organize field trips to tech companies and exhibitions, along with networking events to connect with alumni and professionals in the field.",
    },
    image1:{
        image:img1,
        title:"Campaign on AI conference",
        description:"Conferences on Artificial Intelligence(AI) are a great way for students to discover new ideas, meet experts, and see how AI can be used in the real world. ",
    },
    image2:{
        image:img2,
        title:"Soccer-bot competition",
        description:"Soccer-bots are wirelessly controlled 4 wheelers used to play soccer between two teams. Participants need to prove their robot building skills as well as ability to control it.",
    },
}

export default homeConfig;