import RegistrationForm from "./RegistrationForm";
import EventDetails from "./EventDetails";
import {Tab, Tabs} from "react-bootstrap";

function Competition(props) {

    const tabKey = [
        {id:0,key:"Registration",component:<RegistrationForm/>},
        {id:1,key:"Details",component:<EventDetails/>},
    ]

    return (
       <Tabs className="mx-0 px-0">
           {
               tabKey.map((tab,idx)=>(
                   <Tab className="subTitle" eventKey={tab.key} title={tab.key}>
                       {tab.component}
                   </Tab>
               ))
           }
       </Tabs>
    );
}

export default Competition;

