import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import bannar1 from "../Assets/Images/banner1.jpeg"
import regiConfig from "../Config/RegiConfig";

function EventDetails(props) {
    return (
        <Container fluid="true" className="mt-0">
            <Row className="mx-0">
                <img className="banner" src={bannar1} alt="bannar1"/>
            </Row>
            <Row className="mx-0 w-75 mx-auto mt-3">
                <h4 className="blackTitle text-center text-dark mb-3"><strong>Web Code Craft 2024</strong> </h4>
                <div> <p>
'Web Code Craft 2024' is a website developing competition for students of Jahangirnagar University. 
Participants have to create a demo website for our club before the event date and present it on the event.</p>

 <ul>
    <li>Participants must be student of Jahangirnagar University. Can participate in individual or with a team of 3 members maximum.</li>
<li>
The website is required to be dynamic and should have features to upload and maintain events. Any other creative features are appreciated.
</li>
<li>It wiil be wise to maintain clean code, valid pattern, proper documentation and avoid redundancy. </li>
 </ul>

<p>

<strong>Event Time: </strong> 16 November, 2024 at 10 AM.

<br></br>
<strong>Venue:  </strong>

Room No. 202, Department of Computer Science and Engineering, Jahangirnagar University.
<pre>
    {/* {'\n'} */}
  </pre>
To participate, 
Please register to the event.
No payment is required for the registration. 
Follow our Facebook page for further updates.
</p></div>
                <Col lg={6} md={6} sm={6}>
                    <h3 className="subTitle text-dark"><strong>Prize money</strong></h3>
                    {
                        regiConfig && regiConfig.prize.map((item) => (
                            <h4 className="blackDescription">{item.description}</h4>
                        ))
                    }
                </Col>
                <Col lg={6} md={6} sm={6} >
                    {
                        regiConfig && regiConfig.description.map((item) => (
                            <h4 className="blackDescription">{item.description}</h4>
                        ))
                    }
                </Col>
            </Row>
            {/* <Row className="mx-0 mt-4">
                <ul className="blackDescription text-dark mt-2">
                    {
                        regiConfig && regiConfig.process.map((item) => (
                            <h4 className="blackDescription">{item.description}</h4>
                        ))
                    }
                </ul>
            </Row> */}
        </Container>
    );
}

export default EventDetails;
