import React from 'react';
import { Button, Nav, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGithub } from "@fortawesome/free-brands-svg-icons";
import {Container} from "react-bootstrap";
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons/faAlignCenter';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';

function Footer(props) {
    return (
        <Container fluid="true" className="footer">
            <Row className="mx-5 justify-content-md-center">
                <Col xs = {7} >
                    <h5>Contact Us</h5>
                    <ul className="list-unstyled">
                         <li> <strong> Email:</strong> jurc.official@gmail.com</li>
                        <li><b>Phone:</b> +8801907925486</li>
                        <li><b>Address:</b>  Room No.165 (Prof. Dr. M. Zahidur Rahman), Department of Computer Science and Engineering, Jahangirnagar University, Savar, Dhaka, Bangladesh.</li>
                    </ul>
                </Col>
                <Col xs = {5} style={{textAlign:"center"}} >
                    <h5>Follow Us</h5>
                    <ul className="list-unstyled center">
                        <li><a href="https://web.facebook.com/profile.php?id=61562560401669">                                 <FontAwesomeIcon className="icon" icon={faFacebook} /></a></li>
          
                      {/*  <li><a href="#a">Twitter</a></li> 
                        <li><a href="#a">Instagram</a></li> */}
                    </ul>
                </Col>
                {/* <Col  md={2}>
                    <h5>Quick Links</h5>
                    <ul className="list-unstyled">
                        <li><a href="#a">Home</a></li>
                        <li><a href="#a">About</a></li>
                        <li><a href="#a">Events</a></li>
                        <li><a href="#a">Contact</a></li>
                    </ul>
                    </Col> */}
            </Row>
        </Container>
    );
}

export default Footer;
