


const regiConfig = {
    prize:[
        {
            id:0,
            description:"1st prize : 5000 BDT",
        },
        {
            id:1,
            description:"2nd prize : 1000 BDT",
        },
        {
            id:2,
            description:"3rd prize : 1000 BDT",
        },
        {
            id:3,
            description:"4th prize : 1000 BDT",
        },
    ],
    description:[

        {
            id:1,
            description:
            <div className="regiDate">  
            <strong>Last date of registration: 30/10/2024</strong>
            </div>,
        },
    ],
    process:[
        {
            id:0,
            description:"Process starts on : date",
        },
    ]
}

export default regiConfig;