import React, { useState, useEffect } from 'react';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import logo from '../Assets/Images/logo.png';
import { Link } from "react-router-dom";
import headerImage from "../Assets/Images/jurcWebTitle.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alignPropType } from 'react-bootstrap/esm/types';



function Menu(props) {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // if (window.scrollY > 0) {
            //     setIsSticky(true);
            // } else {
            setIsSticky(true);
            //  }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    
    const [gap, setGap] = useState('20px'); // Default gap

    // Function to update the gap based on screen width
    const updateGap = () => {
      const width = window.innerWidth;
  
      if (width < 650) {
        setGap('0px'); // Small gap for narrow screens
      } else if (width < 1300) {
        setGap('40px'); // Medium gap for medium screens
      } else {
        setGap('60px'); // Larger gap for wide screens
      }
    };
  
    // Set up event listener on mount and clean up on unmount
    useEffect(() => {
      updateGap(); // Initial gap update
      window.addEventListener('resize', updateGap); // Update gap on resize
  
      return () => {
        window.removeEventListener('resize', updateGap); // Clean up on unmount
      };
    }, []); // Empty dependency array means this effect runs once on mount
  
    return (
        <Navbar expand="lg" sticky={isSticky ? "top" : ""} className="mainNavBody">
            <Navbar.Brand as={Link} to="/">
                <img className="logo" src={logo} alt="logo" />
                <img className="headerImage" src={headerImage} alt="Header" />
                <FontAwesomeIcon icon="fa-solid fa-phone" />
            </Navbar.Brand>
            <div className='rightMenu' >
                <div className='contacts'>
                    <div className='email' style={{ paddingInline: gap }} >
                        <b>Email :  </b>  jurc.official@gmail.com  </div>
                 <div >
                       <span> <strong> Hotline :   </strong> </span> <span> 01907925486  </span> </div>
                </div>

                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="ms-auto smallNav" navbarScroll>

                        <div className='pageMenu'>
                            <Nav.Link as={Link} to="/" className="navLink" style={{ paddingInlineEnd: "35px" }}>
                                Home
                            </Nav.Link>
                            {/*  <Nav.Link className="navLink" href="#action2">
                        jucse
    </Nav.Link>      */}
                            <Dropdown>
                                <Dropdown.Toggle className="dropDown navLink" id="dropdown-basic">
                                    Events
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropMenu">
                                    <Dropdown.Item className="navLink" as={Link} to="/registration" style={{justifyContent: "center"}} >
                                        Web Code Craft 2024
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Nav.Link className="navLink" href="#" style={{ paddingInlineEnd: "35px" }}>
                                Gallery
                            </Nav.Link>
                            <Nav.Link className="navLink" href="#">
                                Members
                            </Nav.Link>

                        </div>
                        {/* </div> */}
                    </Nav>

                </Navbar.Collapse>
            </div>
        </Navbar >
    );
}

export default Menu;
// 